// import { isMobile } from '@/utils'

export default {
  address: '新北市新店央北重劃區斯馨一路',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7233.252158812381!2d121.52602593092891!3d24.97883350000125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDU4JzQzLjgiTiAxMjHCsDMxJzQ5LjUiRQ!5e0!3m2!1szh-TW!2stw!4v1595248591885!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/oDntr7BDpXfMsJat5',
  phone: '02-86676688',
  fbLink:
    'https://www.facebook.com/央北鑫建築-107215314395480/',
  fbMessage: 'https://m.me/107215314395480/',
  caseName: '央北鑫建築',
  houseInfos: [
    ['投資興建', '鑫忠建築開發股份有限公司'],
    ['建築規劃', '張維哲建築師事務所'],
    ['燈光設計', '一隱照明設計顧問有限公司 朱文英'],
    ['公設規劃', 'Mark Lintott Design 林馬克 設計有限公司'],
    ['景觀設計', '太研規劃設計顧問有限公司 吳書原'],
    ['結構設計', '凱巨工程顧問有限公司 陳村林'],
    ['綠建築顧問', '楊謙柔 博士'],
    ['建築代銷', '聯碩地產股份有限公司'],
    ['經紀人', '戴子順 (96)竹縣地經字第00150'],
  ],

  gtmCode: ['TK7BQ55'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  },
}
